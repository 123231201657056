import { send } from "../index";

export function getUnitList(data, opts = {}) {
  return send({
    url: "/admin/unit/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveUnit(data, opts = {}) {
  return send({
    url: "/admin/unit/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateUnit(data, opts = {}) {
  return send({
    url: "/admin/unit/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteUnit(data, opts = {}) {
  return send({
    url: "/admin/unit/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getDepartmentList(data, opts = {}) {
  return send({
    url: "/admin/department/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveDepartment(data, opts = {}) {
  return send({
    url: "/admin/department/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateDepartment(data, opts = {}) {
  return send({
    url: "/admin/department/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteDepartment(data, opts = {}) {
  return send({
    url: "/admin/department/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}
