<template>
	<div>
		<a-spin :spinning="loading">
			<a-row>
				<a-col :span="18">
					<a-button @click="onSearchBtn">
						<template #icon><Icon icon="SearchOutlined"/></template>
						查询</a-button>
					<exportModal class="ui-exportBrn" v-permission="['pc_system_unit_export']" :type="type" :searchData="selIdsData" :condition="condition" :role="role" :isAll="1">
						<a-button>
							<template #icon><Icon icon="UploadOutlined"/></template>
							导出</a-button>
					</exportModal>
					<exportModal class="ui-exportBrn" v-permission="['pc_system_unit_export']" style="margin-right: 16px;" :type="type" :searchData="searchData" :condition="condition" :role="role" :isAll="0">
						<a-button>
							<template #icon><Icon icon="LogoutOutlined"/></template>
							导出全部</a-button>
					</exportModal>
					<a-button @click="onSearch">
						<template #icon><Icon icon="ReloadOutlined"/></template>
						刷新</a-button>
					<a-button @click="onSort">
						<template #icon><Icon icon="OrderedListOutlined"/></template>
						排序</a-button>
				</a-col>
				<a-col :span="6" style="text-align: right;">
					<a-button v-permission="['pc_system_unit_add']" type="primary" @click="onAdd">新增单位</a-button>
				</a-col>
			</a-row>
			
			<div v-show="isSearch" class="ui-form">
				<a-form class="label-width" ref="formRef" :model="formModal" layout="inline">
					<a-form-item label="单位名称" name="name" class="ui-form__item">
						<a-input v-model:value="formModal.name" placeholder="请输入单位名称"></a-input>
					</a-form-item>
					<div style="text-align: center;">
						<a-button @click="onReset">重置</a-button>
						<a-button type="primary" @click="onSearch">搜索</a-button>
					</div>
				</a-form>
			</div>
			
			<div v-show="isSort" class="ui-form" style="padding-bottom: 20px;">
				<a-row style="align-items: center;">
					<a-col :span="18">
						<a-checkbox-group v-model:value="sortList">
							<a-checkbox :value="'name'">单位名称</a-checkbox>
						</a-checkbox-group>
					</a-col>
					<a-col :span="6" style='text-align: right;'>
						<a-button type="primary" @click="onSortClick('asc')">升序</a-button>
						<a-button type="primary" @click="onSortClick('desc')">降序</a-button>
						<a-button type="primary" @click="onResetSortClick()">重置</a-button>
					</a-col>
				</a-row>
			</div>
			
			<div style="margin-top: 20px;">
				<c-Table ref="cTable" :columns="columns" :isRequest="true" :requestFun="getDataFun" rowSelectionType="checkbox" :searchData="searchData" rowKey="id" @rowSelected="rowSelected" :scroll="{ x: 1500 }" bordered draggable>
					<template #bodyCell="{ column, record, index, pagination }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-permission="['pc_system_unit_edit']" type="link" size="small" @click="onEdit(record)">编辑</a-button>
							<span></span>
							<a-button v-permission="['pc_system_unit_delete']" type="link" size="small" danger @click="onDelete(record)">删除</a-button>
						</template>
					</template>
				</c-Table>
				
				<!-- <a-table rowKey="id" class="table"
					:columns="columns" 
					:dataSource="list" 
					:pagination="pagination" bordered 
					size="small"
					:scroll="{ x: 1500 }"
				>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-permission="['pc_system_unit_edit']" type="link" size="small" @click="onEdit(record)">编辑</a-button>
							<span></span>
							<a-button v-permission="['pc_system_unit_delete']" type="link" size="small" danger @click="onDelete(record)">删除</a-button>
						</template>
					</template>
				</a-table> -->
			</div>
		</a-spin>
		<saveModal ref="saveModalRef" @refresh="onReset"></saveModal>
	</div>
</template>

<script>
	import { Icon } from '@/components/icon/icon.js';
	import saveModal from './components/saveModal.vue';
	import cTable from '@/components/cTable/index.vue';
	import { getUnitList, deleteUnit } from '@/service/modules/unit.js';
	import exportModal from '@/components/exportModal/exportModal.vue';
	export default {
		components: { Icon, saveModal, cTable, exportModal },
		data() {
			return {
				loading: false,
				isSearch: false,
				isSort: false,
				isExpand: false,
				searchData: {},
				formModal: {
					name: ''
				},
				getDataFun: getUnitList,
				list: [],
				type: 'unitMangerStrategy', // 导出类型
				role: 1, // 导出角色
				condition: '3', // 导出条件
				selIdsData: {},
				sortList:[],
				columns: [{
					title: '行号',
					align: 'center',
					key: "index",
					width: 80
				}, {
					title: '单位名称',
					align: 'center',
					dataIndex: "name"
				}, {
					title: '资产总数(件)',
					align: 'center',
					dataIndex: "assetsNum"
				}, {
					title: '资产总价值(元)',
					align: 'center',
					dataIndex: "amount"
				}, {
					title: '借出资产(件)',
					align: 'center',
					dataIndex: "lendNum"
				}, {
					title: '借入资产(件)',
					align: 'center',
					dataIndex: "borrowNum"
				}, {
					title: '调出资产(件)',
					align: 'center',
					dataIndex: "exportNum"
				}, {
					title: '调入资产(件)',
					align: 'center',
					dataIndex: "importNum"
				}, {
					title: '累计盘亏(件)',
					align: 'center',
					dataIndex: "loseNum"
				}, {
					title: '盘亏金额(元)',
					align: 'center',
					dataIndex: "loseMoney"
				}, {
					title: '逾期次数',
					align: 'center',
					dataIndex: "overdueNum"
				}, {
					title:'操作',
					align: 'center',
					key: 'action',
					fixed: 'right',
					width: 130
				}],
			}
		},
		created() {
			this.onSearch()
		},
		methods: {
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formModal));	
				// this.pagination.current = 1;
				// console.log("sear", this.searchData,this.formModal.name)
				// this.getData();
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			onReset() {
				this.$refs.formRef.resetFields();
				this.onSearch();
			},
			// async getData() {
			// 	this.loading = true;
			// 	try {
			// 		let ret = await getUnitList({
			// 			page: this.pagination.current,
			// 			pageSize: this.pagination.pageSize,
			// 			...this.searchData
			// 		});
			// 		this.loading = false;
			// 		if (ret.code === 200) {
			// 			this.list = ret.data.list;
			// 			this.pagination.total = ret.data.totalCount;
			// 			// console.log("单位",this.list)
			// 		}
			// 	} catch(e) {
			// 		this.loading = false;
			// 	}
			// },
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			onSort() {
				this.isSort = !this.isSort;
			},
			onAdd() {
				this.$refs.saveModalRef.open(0);
			},
			onEdit(item) {
				this.$refs.saveModalRef.open(item);
			},
			rowSelected(item) {
				this.selIdsData.ids = item.keys.join(',')
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: "确定删除吗？",
					onOk: async ()=> {
						this.loading = true;
						try {
							let ret = await deleteUnit({
								id: item.id
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('删除成功');
								// this.getData();
								this.onSearch()
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onSortClick(type){
				let list = [];
				this.sortList.forEach(element => {
					list.push({key:element,sort:type});
				});
				this.formModal.sort = list;
				console.log(this.formModal);
				this.onSearch();
			},
			onResetSortClick(){
				this.sortList = [];
				this.formModal.sort = undefined;
				this.onSearch();
			}
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 20px;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		text-align: center;
	}
	.ui-exportBrn {
		margin-left: 16px;
	}
</style>