<template>
	<div>
		<a-modal v-model:visible="visible" width="700px" @cancel="close" @ok="onSubmit">
			<template #title>
				<modalHeader :title="isEdit ? '编辑单位' : '新增单位'"></modalHeader>
			</template>
			
			<a-spin :spinning="loading">
				<a-form ref="formRef" :model="modelRef" :label-col="{ span: 5 }" :wrapper-col="{ span: 17 }">
					<a-form-item label="单位名称" name="name" :rules="[{ required: true, message: '请输入单位名称!' },{ max: 20, min: 1, message: '单位名称请保持1~20字符之间!' }]">
						<a-input v-model:value="modelRef.name" placeholder="请输入单位名称"></a-input>
					</a-form-item>
					<a-form-item label="单位简称代码" name="simpleCode" :rules="[{ required: true, message: '请输入单位简称!' },{ max: 20, min: 1, message: '单位简称不得大于单位名称',pattern: /^[A-Za-z]+$/}]">
						<a-input v-model:value="modelRef.simpleCode" placeholder="请输入单位简称代码"></a-input>
					</a-form-item>
					<a-form-item label="单位简称" name="simpleName" :rules="[{ required: true, message: '请输入单位简称!' },{ max: 20, min: 1, message: '单位简称不得大于单位名称'}]">
						<a-input v-model:value="modelRef.simpleName" placeholder="请输入单位简称"></a-input>
					</a-form-item>
					<a-form-item label="单位类型" name="type" :rules="[{required:true,message:'请选择单位类型'}]">
						<a-radio-group v-model:value="modelRef.type">
						  <a-radio :value="1">主管单位</a-radio>
						  <a-radio :value="2">非主管单位</a-radio>
						</a-radio-group>
					</a-form-item>
					<a-form-item label="主管单位选择" :rules="modelRef.type == 2 ? [{required:true,message:'请选择主管单位'}]:[]">
						<a-select v-model:value="modelRef.parentId" style="width:200px;" :disabled="modelRef.type == 1 ? true : false">
						   <a-select-option v-for="item in options" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item label="财政区域代码" name="fCode" :rules="[{required:true,message:'请输入财政区域代码'}]">
						<a-input v-model:value="modelRef.fCode"></a-input>
					</a-form-item>
					<a-form-item label="预算单位代码" name="bCode" :rules="[{required:true,message:'请输入预算单位代码'}]">
						<a-input v-model:value="modelRef.bCode"></a-input>
					</a-form-item>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import { Icon } from '@/components/icon/icon.js';
	import eventBus from '@/utils/eventBus.js';
	import modalHeader from '@/components/modalHeader/index.vue';
	import { saveUnit, updateUnit } from '@/service/modules/unit.js';
	import {getAllUnitList} from '@/service/modules/global.js';
	export default {
		components: { Icon, modalHeader },
		data() {
			return {
				loading: false,
				visible: false,
				isEdit: false,
				isOpen: false,
				modelRef: {},
				options: []
			}
		},
		methods: {
			onSubmit() {
				this.$refs.formRef.validate().then(async () => {
					let postData = JSON.parse(JSON.stringify(this.modelRef));
					this.loading = true;
					try {
						let ret;
						if (this.isEdit) {
							ret = await updateUnit(postData);
						} else {
							ret = await saveUnit(postData);
						}
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success(this.isEdit ? '编辑成功' : '新增成功');
							this.$emit('refresh');
							eventBus.$emit('refreshUnit');
							this.getAllUnitList()
							this.close();
							
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			async getAllUnitList(type) {
				let ret = null;
				if(type){
					ret = await getAllUnitList({type})
					this.options = ret.data;	
				}else{
					ret = await getAllUnitList({});
					if (ret.code === 200) {
						this.$store.commit('count/setUnitList', ret.data || []);
					}
				}
				
			},
			open(e) {
				if (e && e.id) {
					this.modelRef = {
						id: e.id,
						name: e.name,
						type: 2
					}
					this.isEdit = true;
				} else {
					this.modelRef = {
						type: 2
					};
					this.isEdit = false;
				}
				this.visible = true;
				this.getAllUnitList(1);
			},
			close() {
				this.$refs.formRef.clearValidate();
				this.visible = false;
			}
		}
	}
</script>

<style>
</style>